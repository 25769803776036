import React from 'react';
import { useSelector } from 'react-redux';
import ActiveTrialsSection from './ActiveTrialsSection';
import ExpandableRow from './reusableComponents/Accordion/ExpandableRow';
import './ActiveTrials.scss';
import { numberWithCommas } from './Helper';
import StaticLoadingWidget from '../../containers/formField/StaticLoadingWidget';
import { Button } from '@material-ui/core';

const ActiveTrialsGrid = ({isAllTrials, options, apiCallRequest, loadMoreRequest}) => {
  const trialsStore = useSelector((state) => state.trials);
  const { activeTrialsData, activeTrialsDataLevelLeaf, allTrialsData, dataArrangementTrials, dataArrangement } = trialsStore;
  let trials = dataArrangementTrials;
  let totalTrials = 0 //countArrayLengths(trials, 2);

  if(activeTrialsDataLevelLeaf['axl_primary_intervention']){
    options.leafModalData = {
      name:options.levelLeaf,
      nextData:activeTrialsDataLevelLeaf['axl_primary_intervention']
    }
  }

  return (
    <div className='expandable-table'>
      <div className='expandable-header-row'
      >
        <div className='expandable-cell'>
        {!isAllTrials ? 'Active Trials' : 'All Trials'}
        </div>
        <div className='expandable-cell'>
        #
        </div>
      </div>

      {trials.map((item, index) => {
        totalTrials += item['total']
        return (
          <>
            {/* {grouping[item[0]] && (
              <div 
                className="expandable-row appr-subheading grouping-row"
              >
                <div className='expandable-cell'>
                  {grouping[item[0]]}
                </div>
                <div className='expandable-cell'>
                </div>
              </div>
            )} */}
            <ExpandableRow apiCallRequest={apiCallRequest} options={options} item={item} />
            {trials.length == 0 && <h3 class='text-center'>No matching records found!</h3>}
          </>
        );
      })
      }
      {options.dataCount > options.offset_limit && <div className='d-flex justify-content-center'><Button onClick={loadMoreRequest} style={{
        border : '1px solid #2bb670',
        borderRadius : '3px',
        padding: '2px 8px',
        margin: '5px 0px',
        color: '#ffffff',
        fontSize:'12px',
        backgroundColor:'#2bb670'
      }}>Load More</Button></div>}
      
      <div className='expandable-footer-row'>
        <div className='expandable-cell'>
          Total
        </div>
        <div
          className='expandable-cell'
          style={{textAlign: 'right', marginRight: '24px'}}
        >
          {numberWithCommas(totalTrials)}
        </div>
      </div>
    </div>
  );
};

export default ActiveTrialsGrid;
