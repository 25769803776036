import React, { useState, useEffect, useRef } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { numberWithCommas } from '../../Helper';
import ExpandableMiddleRow from './ExpandableMiddleRow';
import './ExpandableTable.scss';
import { Button } from '@material-ui/core';
import LeafRow from './LeafRow';

const ExpandableSecondRow = ({options, item, apiCallRequest}) => {
  const [DevicesRowClicked, setDevicesRowClicked] = useState(false);
  let btnRef = useRef();
  const columnSize = options.trialType=='phases'?30:45;
  const handleCallRequest = () => {
    setDevicesRowClicked(!DevicesRowClicked)
    // if(options.trialType == 'trials'){
      apiCallRequest(item['name'], item['id'], item['is_leaf'], 0, 'level3', btnRef.current.getAttribute("apiclick"), item['parent'])
    // }
    if(btnRef.current){
      btnRef.current.setAttribute("apiclick", "yes");
    }
  }
  const loadMoreRequest = () =>{
    apiCallRequest(item['name'], item['id'], item['is_leaf'], item['offset'], 'level3', 'no', item['parent'])
  }
  return (
    <>
      <div apiclick="no" ref={btnRef}
          className={`expandable-row appr-subheading expand-second-row ${DevicesRowClicked ? 'expanded' : ''}`}
          onClick={handleCallRequest}
        >
          <div className='expandable-cell text-capitalize'>
            <div style={{ display: 'inline'}}>
              {DevicesRowClicked ? (
                <ExpandMoreIcon
                  style={{ color: DevicesRowClicked ? 'black' : 'black' }}
                />
              ) : (
                <ExpandMoreIcon
                  style={{transform: 'rotate(-90deg)'}}
                />
              )}
            </div>
            {item['name'].length > columnSize?item['name'].toLowerCase().substring(0,columnSize)+'...':item['name'].toLowerCase()}
          </div>
          <div
            className='expandable-cell'
            style={{textAlign: 'right'}}
          >
            {numberWithCommas(item['total'])}
          </div>
        </div>
        {DevicesRowClicked === true &&
        item['nextData'].map((subitem, index) => {
          return (item['is_leaf'] == 'true' ? <LeafRow apiCallRequest={apiCallRequest} options={options} item={subitem} />:
            <ExpandableMiddleRow apiCallRequest={apiCallRequest} options={options} item={subitem} />
          );
        })
      }
      {item['tot_row_count'] > item['offset'] && <div className='d-flex justify-content-center'>
        {DevicesRowClicked && <Button onClick={loadMoreRequest} style={{
            border : '1px solid',
            borderRadius : '3px',
            padding: '2px 8px',
            margin: '5px 0px',
            color: 'black',
            fontSize:'12px'
          }}> Load More</Button>}
      </div>}
    </>
  )
}

export default ExpandableSecondRow;