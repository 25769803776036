import React, {useState} from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams, Link } from 'react-router-dom';
import { nameToInitials, numberWithCommas } from './Helper';
import CIcon from '@coreui/icons-react';
import ListPopover from '../../containers/formField/ListPopover';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function CompanyDealsModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader style={{zIndex:'9999'}}
              className={classes.header}
              title={item['name']}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              <Card className={classes.cards}>
                <CardContent style={{padding:0}}>
                  <div className="container">
                    <div className="row pt-3">
                      <div className="col-12">
                        <table class="table table-bordered modal-table text-capitalize">
                            {item['nextData']['date_closed'] && <tr>
                              <th class='font-weight-bold'>Date closed</th>
                              <th>{item['nextData']['date_closed']}</th>
                            </tr>}
                            {item['nextData']['date_announced']&&<tr>
                              <th class='font-weight-bold'>Date announced</th>
                              <th>{item['nextData']['date_announced']}</th>
                            </tr>}
                            {item['nextData']['acquiror'] &&<tr>
                              <th class='font-weight-bold'>Acquiror</th>
                              <th>{item['nextData']['acquiror']}</th>
                            </tr>}
                            {item['nextData']['target'] &&<tr>
                              <th class='font-weight-bold'>Target</th>
                              <th>{item['nextData']['target']}</th>
                            </tr>}
                            {item['nextData']['total_enterprise_value'] &&<tr>
                              <th class='font-weight-bold'>Total Enterprise Value</th>
                              <th>{item['nextData']['total_enterprise_value']}</th>
                            </tr>}
                            {item['nextData']['upfront_cash'] &&<tr>
                              <th class='font-weight-bold'>Upfront Cash</th>
                              <th>{item['nextData']['upfront_cash']}</th>
                            </tr>}
                            {item['nextData']['upfront_stock'] &&<tr>
                              <th class='font-weight-bold'>Upfront Stock</th>
                              <th>{item['nextData']['upfront_stock']}</th>
                            </tr>}
                            {/* <tr>
                              <th>Divestitures</th>
                              <th>{item['nextData']['']}</th>
                            </tr> */}
                            {item['nextData']['revenues_previous_year'] &&<tr>
                              <th class='font-weight-bold'>Target Revenues (previous year)</th>
                              <th>{item['nextData']['revenues_previous_year']}</th>
                            </tr>}
                            {item['nextData']['net_income_previous_year'] &&<tr>
                              <th class='font-weight-bold'>Target Profitability (previous year)</th>
                              <th>{item['nextData']['net_income_previous_year']}</th>
                            </tr>}
                            {item['nextData']['target_description'] &&<tr>
                              <th class='font-weight-bold'>Target Description</th>
                              <th>{item['nextData']['target_description']}</th>
                            </tr>}
                            {item['nextData']['employees_acquired'] &&<tr>
                              <th class='font-weight-bold'>Number of Target Employees at time of acquisition</th>
                              <th>{item['nextData']['employees_acquired']}</th>
                            </tr>}
                            {item['nextData']['sec_document_acquired'] &&<tr>
                              <th class='font-weight-bold'>SEC Document URL (Acquiror) 8K filing</th>
                              <th>
                                <Link
                                  target="_blank"
                                  title="SEC Document URL (Acquiror) 8K filing"
                                  to={{
                                    pathname: item['nextData']['sec_document_acquired'],
                                  }}>
                                  <i class='fas fa-external-link-alt'></i>
                                </Link>
                              </th>
                            </tr>}
                            {item['nextData']['sec_document_acquiror'] &&<tr>
                              <th class='font-weight-bold'>SEC Document URL (10K in the year of the acquisition</th>
                              <th class='font-weight-bold'>
                                <Link
                                  target="_blank"
                                  title="SEC Document URL (10K in the year of the acquisition"
                                  to={{
                                    pathname: item['nextData']['sec_document_acquiror'],
                                  }}>
                                  <i class='fas fa-external-link-alt'></i>
                                </Link>
                              </th>
                            </tr>}
                            {item['nextData']['source_news_announcement'] &&<tr>
                              <th class='font-weight-bold'>Press Release URL</th>
                              <th>
                                <Link
                                  target="_blank"
                                  title="Press Release URL"
                                  to={{
                                    pathname: item['nextData']['source_news_announcement'],
                                  }}>
                                  <i class='fas fa-external-link-alt'></i>
                                </Link>
                              </th>
                            </tr>}
                            {item['nextData']['source_news_closing'] &&<tr>
                              <th class='font-weight-bold'>Second Source URL</th>
                              <th>
                                <Link
                                  target="_blank"
                                  title="Second Source URL"
                                  to={{
                                    pathname: item['nextData']['source_news_closing'],
                                  }}>
                                  <i class='fas fa-external-link-alt'></i>
                                </Link>
                              </th>
                            </tr>}
                            {item['nextData']['source_other'] &&<tr>
                              <th class='font-weight-bold'>Third Source URL</th>
                              <th>
                                <Link
                                  target="_blank"
                                  title="Third Source URL"
                                  to={{
                                    pathname: item['nextData']['source_other'],
                                  }}>
                                  <i class='fas fa-external-link-alt'></i>
                                </Link>
                              </th>
                            </tr>}
                            {item['nextData']['net_equity'] &&<tr>
                              <th class='font-weight-bold'>Target Equity (from last balance sheet if public) </th>
                              <th>{item['nextData']['net_equity']}</th>
                            </tr>}
                            {item['nextData']['axl_modality'] &&<tr>
                              <th class='font-weight-bold'>Target Modalities</th>
                              <th>{item['nextData']['axl_modality']}</th>
                            </tr>}
                            {item['nextData']['group_of_conditions'] &&<tr>
                              <th class='font-weight-bold'>Target Group of Conditions (Specialization or diseases) for which drugs are being made</th>
                              <th>{item['nextData']['group_of_conditions']}</th>
                            </tr>}
                            {item['nextData']['individual_conditions'] &&<tr>
                              <th class='font-weight-bold'>Target Individual Conditions (diseases) for which drugs are being made</th>
                              <th>{item['nextData']['individual_conditions']}</th>
                            </tr>}
                            {item['nextData']['approved_drugs'] &&<tr>
                              <th class='font-weight-bold'>Approved Drugs</th>
                              <th>{item['nextData']['approved_drugs']}</th>
                            </tr>}
                            {item['nextData']['pipeline_phases_in_brackets'] &&<tr>
                              <th class='font-weight-bold'>Target Pipeline Drugs (Write Phases in brackets)</th>
                              <th>{item['nextData']['pipeline_phases_in_brackets']}</th>
                            </tr>}
                            {item['nextData']['axl_acquiror_ceo'] &&<tr>
                              <th class='font-weight-bold'>Acquiror CEO</th>
                              <th>{item['nextData']['axl_acquiror_ceo']}</th>
                            </tr>}
                            {item['nextData']['axl_company_acquiror_country'] &&<tr>
                              <th class='font-weight-bold'>Acquiror Country</th>
                              <th>{item['nextData']['axl_company_acquiror_country']}</th>
                            </tr>}
                            {item['nextData']['axl_company_seller'] &&<tr>
                              <th class='font-weight-bold'>Company Seller</th>
                              <th>{item['nextData']['axl_company_seller']}</th>
                            </tr>}
                            {item['nextData']['axl_company_target_country'] &&<tr>
                              <th class='font-weight-bold'>Company Target Country</th>
                              <th>{item['nextData']['axl_company_target_country']}</th>
                            </tr>}
                            {item['nextData']['axl_deal_date'] &&<tr>
                              <th class='font-weight-bold'>Deal Date</th>
                              <th>{item['nextData']['axl_deal_date']}</th>
                            </tr>}
                            {item['nextData']['axl_ma_pr_date'] &&<tr>
                              <th class='font-weight-bold'>MA PR Date</th>
                              <th>{item['nextData']['axl_ma_pr_date']}</th>
                            </tr>}
                            {/* {item['nextData']['axl_ma_pr_text'] &&<tr>
                              <th class='font-weight-bold'>MA PR Text</th>
                              <th>{item['nextData']['axl_ma_pr_text']}</th>
                            </tr>} */}
                            {item['nextData']['axl_ma_pressrelease'] &&<tr>
                              <th class='font-weight-bold'>MA Pressrelease</th>
                              <th>
                                <Link
                                  target="_blank"
                                  title="MA Pressrelease"
                                  to={{
                                    pathname: item['nextData']['axl_ma_pressrelease'],
                                  }}>
                                  <i class='fas fa-external-link-alt'></i>
                                </Link>
                              </th>
                            </tr>}
                            {item['nextData']['axl_target_ceo'] &&<tr>
                              <th class='font-weight-bold'>Target CEO</th>
                              <th>{item['nextData']['axl_target_ceo']}</th>
                            </tr>}
                            {item['nextData']['axl_target_deal_rationale'] &&<tr>
                              <th class='font-weight-bold'>Target Deal Rationale</th>
                              <th>{item['nextData']['axl_target_deal_rationale']}</th>
                            </tr>}
                        </table>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default CompanyDealsModal;