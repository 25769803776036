import React, {useState} from 'react';
import { Modal, Backdrop, Fade, Card, CardHeader, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { useParams, Link } from 'react-router-dom';
import { nameToInitials, numberWithCommas } from '../Helper';
import CIcon from '@coreui/icons-react';
import doximity from '../../../assets/img/doximity.png';
import linkedin from '../../../assets/img/linkedin.png';
import usnews from '../../../assets/img/usnews.png';
import ListPopover from '../../../containers/formField/ListPopover';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '80%',
    maxWidth: '70%',
    backgroundColor: '#fff',
    overflowY: 'auto',
    maxHeight: '80%',
    position: 'relative',
    boxShadow: 'none',
  },
  header: {
    textAlign: 'center',
    background: '#2bb670',
    fontWeight: 'bold',
    padding: '10px 0',
    position: 'sticky',
    top: 0,
    '& span': {
      color: '#fff !important',
    }
  },
  cards: {
    marginTop: 20,
    padding: 0,
  },
  cardsHeader: {
    display: 'flex',
    background: '#c5ecda',
    fontWeight: 'bold',
    padding: '10px 10px',
    '& h3' : {
      fontSize: 18,
      fontWeight: 'bold',
      margin: 0,
    },
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  leftCard: {
    width: '70%',
  },
  rightCard: {
    width: '30%',
    textAlign: 'right',
  },
  cardsBody: {
    display: 'flex',
    padding: 0,
    '& p' : {
      fontSize: 14,
      margin: 0,
    },
  },
  cardsBodyLeft: {
    width: '40%',
    padding: 10,
  },
  cardsBodyRight: {
    width: '60%',
    padding: 10,
    borderLeft: '1px solid #ccc',
    paddingLeft: 10,
  },
  closeBtn: {
    position: 'fixed',
    top: 0,
    right: 0,
    background: '#c5ecda',
    border: 'none',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#387249',
    padding: '11px 20px',
    cursor: 'pointer',
  },
}));

function PhysiciansModal({ item, onClose }) {
  const { diseaseName } = useParams();
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    {item} &&
      <Modal
        className={classes.modal}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <Card className={classes.card}>
            <CardHeader style={{zIndex:'9999'}}
              className={classes.header}
              title={item['name']}
            />
            <button className={classes.closeBtn} onClick={handleClose}>x</button>
            <CardContent>
              <Card className={classes.cards}>
                <CardContent style={{padding:0}}>
                  <div className="container">
                    <div className="row pt-3">
                      <div className="col-12">
                        <table class="table table-bordered modal-table text-capitalize">
                          {item['nextData'] && Object.keys(item['nextData']).map((keyName, i) => {
                              if(keyName != 'axl_company'){
                                return (
                                  <tr>
                                    <th class='font-weight-bold'>{keyName.replaceAll('_',' ')}</th>
                                    <th>{item['nextData'][keyName]}</th>
                                  </tr>
                                )
                              }
                          })}
                        </table>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
  );
};

export default PhysiciansModal;